import React from 'react';
//import logo from './logo.svg';
// eslint-disable-next-line
import './App.css';
//-
//import { Authenticator } from '@aws-amplify/ui-react';
//import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
//--
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

//--- form inputs
const submit_msg = "Change inputs to see results.";
const gest_vals = [23, 24, 25, 26, 27, 28];
const white_matter_damage_vals = [ {key:'Yes', val:1}, {key:'No', val:0}];
const ventriculomegaly_vals = [ {key:'Yes', val:1}, {key:'No', val:0}];
//-- Final outcome colors and text
//const final_outcome_1 = [
//  {key:'green', val:'Low Risk for Motor Impairment'},
//  {key:'yellow', val:'Moderate Risk for Motor Impairment'},
//  {key:'red', val:'High Risk for Motor Impairment'}];
const final_outcome = {
  'green': 'Low Risk for Motor Impairment',
  'yellow': 'Moderate Risk for Motor Impairment',
  'red': 'High Risk for Motor Impairment'};
//Risk limits: For Age 2:
// Less than 0.0456469 probability = Low Risk
// Between 0.0456469 and 0.1047924 = Moderate Risk (mean is 0.0456469)
// Greater than 0.1047924 = High Risk (1 std dev above mean)
// Alternatively, you could use greater than 0.1639379 = Extremely High Risk (2 std dev above mean)
//-
//Risk limits: For Age 10:
// Less than 0.0850701 probability = No Risk
// Between 0.0850701 and 0.1763181 = Moderate Risk (mean is 0.0850701)
// Greater than 0.1763181 = High Risk (1 std dev above mean)
// Alternatively, you could use greater than 0.2675661 = Extremely High Risk (2 std dev above mean)
const final_outcome_limits_2y = [0.0456469, 0.1047924];
const final_outcome_limits_10y = [0.0850701, 0.1763181];

//-- Model Parameters: Child age 2 years or 10 years. Will use different model parameters.
const param_intercept_2y = 0.618223;
const param_intercept_10y = -0.860750;
const param_complete_gest_age_weeks_2y = -0.143256;
const param_complete_gest_age_weeks_10y = -0.066293;
const param_fenton_birth_weight_z_score_2y = 0.070524;
const param_fenton_birth_weight_z_score_10y = 0.176520;
const param_fenton_day_14_weight_z_score_2y = 0.427127;
const param_fenton_day_14_weight_z_score_10y = 0.133075;
const param_fenton_discharge_weight_z_score_2y = -0.348404;
const param_fenton_discharge_weight_z_score_10y = -0.346775;
//--
//const param_white_matter_damage_category = [0, 1];
const param_white_matter_damage_0_2y = -0.430445;
const param_white_matter_damage_1_2y = 0.500921;
const param_white_matter_damage_0_10y = -0.253202;
const param_white_matter_damage_1_10y = 0.267590;
//--
//const param_ventriculomegaly_category = [0, 1];
const param_ventriculomegaly_0_2y = -0.438759;
const param_ventriculomegaly_1_2y = 0.510534;
const param_ventriculomegaly_0_10y = -0.567257;
const param_ventriculomegaly_1_10y = 0.599674;

// Calculator Interpreta4on:
// Subject at age 2
//   with 26 weeks completed Gestational age,
//   Fenton birth weight z-score of -0.5,
//   Fenton day 14 weight z-score of 0.2,
//   fenton discharge weight z-score of 0.6,
//   white matter damage : yes,
//   and ventriculomegaly: yes
// cpalsy_risk = exp(0.618223 +
//              (− 0.143256 × 26) +
//              (0.070524 × − 0.5) +
//              (0.427127 × 0.2) +
//              (− 0.348404 × 0.6) +
//              (− 0.430445 × 1) + [Indicator for White Matter Damage = 0]
//              (+ 0.510534 × 1) [Indicator for Ventriculomegaly = 1])
//             )
//        = 0.012
//-one liner
//Math.exp(0.618223 + (-0.143256 * 26) + (0.070524 * -0.5) + (0.427127 * 0.2) + (-0.348404 * 0.6) + (-0.430445 * 1) + (0.510534 * 1))

class CPalsyInput extends React.Component {
  render() {
    // eslint-disable-next-line
    //var submit_button = '';
    //submit_button = <input type="submit" value="Draft Calculator --todo: text here--" className="subButton"/>;

    const gest_options = gest_vals.map( (ga) =>  <option value={ga}>{ga} weeks</option>);
    const wmd_options = white_matter_damage_vals.map( (wmd) => <option value={wmd.val}>{wmd.key}</option>);
    const ventricg_options = ventriculomegaly_vals.map( (ventricg) => <option value={ventricg.val}>{ventricg.key}</option>);

    var classname_cpalsy_outcome_2y = 'cpalsy-outcome-' + this.props.cpalsy_outcome_2y;  //e.g. cpalsy-outcome-green
    var cpalsy_final_outcome_text_2y = final_outcome[this.props.cpalsy_outcome_2y];
    var classname_cpalsy_outcome_10y = 'cpalsy-outcome-' + this.props.cpalsy_outcome_10y;  //e.g. cpalsy-outcome-green
    var cpalsy_final_outcome_text_10y = final_outcome[this.props.cpalsy_outcome_10y];

    return (
      <form onSubmit={this.props.handleSubmit}>
        <table>
        <tbody>
          <tr>
            <td><b>Gestational age at birth:</b></td>
            <td><div className="cpalsy-input">
              <select name="gest" value={this.props.gest} onChange={this.props.handleInputChange}> {gest_options} </select>
            </div></td>
          </tr>
          <tr>
            <td><b>Fenton birth weight z-score:</b></td>
            <td><div className="cpalsy-input">
              <input name="fbwz_score" type="number" step="0.1" value={this.props.fbwz_score} onChange={this.props.handleInputChange}/>
            </div></td>
          </tr>
          <tr>
            <td><b>Fenton day 14 weight z-score:</b></td>
            <td><div className="cpalsy-input">
              <input name="fd14wz_score" type="number" step="0.1" min="-5.0" value={this.props.fd14wz_score} onChange={this.props.handleInputChange}/>
            </div></td>
          </tr>
          <tr>
            <td><b>Fenton discharge weight z-score:</b></td>
            <td><div className="cpalsy-input">
              <input name="fdwz_score" type="number" step="0.1" value={this.props.fdwz_score} onChange={this.props.handleInputChange}/>
            </div></td>
          </tr>
          <tr>
          <td><b>White Matter Damage on head ultrasound:</b></td>
          <td><div className="cpalsy-input">
            <select name="wmd" value={this.props.wmd} onChange={this.props.handleInputChange}> {wmd_options} </select>
          </div></td>
          </tr>
          <tr>
          <td><b>Ventriculomegaly on head ultrasound:</b></td>
          <td><div className="cpalsy-input">
            <select name="ventricg" value={this.props.ventricg} onChange={this.props.handleInputChange}> {ventricg_options} </select>
          </div></td>
          </tr>
          <tr>
            <td colSpan='2' align='center' className='cpalsy-hr'></td>
          </tr>
          <tr>
          <td className="cpalsy-output" colSpan='2'>
          Estimated likelihood of elevated GMFCS level (2 or greater) at age [2 years]:
          </td>
          </tr>
          <tr className="cpalsy-outcome">
          <td>
           <div> {this.props.cpalsy_risk_2y}
           </div></td>
           <td>
            <div className={classname_cpalsy_outcome_2y}> {cpalsy_final_outcome_text_2y}
            </div></td>
          </tr>
          <tr>
            <td colSpan='2' align='center' className='cpalsy-hr'></td>
          </tr>
          <tr>
          <td className="cpalsy-output" colSpan='2'>
          Estimated likelihood of elevated GMFCS level (2 or greater) at age [10 years]:
          </td>
          </tr>
          <tr className="cpalsy-outcome">
          <td>
           <div>  {this.props.cpalsy_risk_10y}
           </div></td>
           <td>
            <div className={classname_cpalsy_outcome_10y}> {cpalsy_final_outcome_text_10y}
            </div></td>
          </tr>
        </tbody>
        </table>
      </form>
    );
  }
}


class CPalsyCompute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      c_age: 2,
      gest: 26,
      fbwz_score: 0,
      fd14wz_score: 0,
      fdwz_score: 0,
      wmd: 1,
      ventricg: 1,
      cpalsy_risk_2y: submit_msg,
      cpalsy_outcome_2y: '',
      cpalsy_risk_10y: submit_msg,
      cpalsy_outcome_10y: '',
      all_valid_input: false,
      submit_clicked: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.allInputSupplied = this.allInputSupplied.bind(this);
    this.cpalsy_risk_calc= this.cpalsy_risk_calc.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    var value = target.value;
    // Checking if value is a number (in case of 0, add 1 and then check so it returns no-zero value)

    // Limit form entry value of the following three free-form entries to between -5.0 to +5.0
    // If someone enters values outside of these bounds it sets at the bounds (-5/+5)
    if (name === 'fbwz_score' || name === 'fd14wz_score' || name === 'fdwz_score') {
      if (value < -5.0) {
        value = -5.0;
      }

      if(value > 5.0) {
        value = 5.0
      }
    }

    if (Number(value+1) || value === '') {
      this.setState({[name]: value}, () => this.allInputSupplied() );
     //this.setState({[name]: value});
   }
  }

  handleSubmit(event) {
    alert('Submitting: ');
    event.preventDefault();
    this.setState({
      submit_clicked: true
    });
  }

  allInputSupplied() {
    if (
        (Number(this.state.c_age) === 2 || Number(this.state.c_age) === 10) &&
        (Number(this.state.gest) >= 23 &&  Number(this.state.gest) <= 34) &&
        (parseFloat(this.state.fbwz_score) >= -5 && parseFloat(this.state.fbwz_score) <= 5) &&
        (parseFloat(this.state.fd14wz_score) >= -5 && parseFloat(this.state.fd14wz_score) <= 5) &&
        (parseFloat(this.state.fdwz_score) >= -5 && parseFloat(this.state.fdwz_score) <= 5) &&
        (Number(this.state.wmd) === 1 || Number(this.state.wmd) === 0) &&
        (Number(this.state.ventricg) === 1 || Number(this.state.ventricg) === 0)
        ) {
          this.setState({all_valid_input: true});
          var gmf_score = this.cpalsy_risk_calc();
          this.setState({cpalsy_risk_2y: gmf_score['2y']});
          this.setState({cpalsy_outcome_2y: gmf_score['outcome_2y']});
          this.setState({cpalsy_risk_10y: gmf_score['10y']});
          this.setState({cpalsy_outcome_10y: gmf_score['outcome_10y']});
          //this.setState({cpalsy_risk_2y: this.cpalsy_risk_calc()});
    } else {
          this.setState({all_valid_input: false});
          this.setState({cpalsy_risk: submit_msg});
    }
  }

  cpalsy_risk_calc() {
    var gest_val = parseInt(this.state.gest);
    var fbwz_score_val = parseFloat(this.state.fbwz_score);
    var fd14wz_score_val = parseFloat(this.state.fd14wz_score);
    var fdwz_score_val = parseFloat(this.state.fdwz_score);
    var wmd_val = parseInt(this.state.wmd);
    var ventricg_val = parseInt(this.state.ventricg);

    var param_wmd_selected = 0;
    var param_ventricg_selected = 0;

    //2-Years calculations
    //-- Select WMD params
    if (wmd_val === 0) {
      param_wmd_selected = param_white_matter_damage_0_2y;
    } else if (wmd_val === 1) {
      param_wmd_selected = param_white_matter_damage_1_2y;
    }
    //-- Select Ventriculomegaly param
    if (ventricg_val === 0) {
      param_ventricg_selected = param_ventriculomegaly_0_2y;
    } else if (ventricg_val === 1) {
      param_ventricg_selected = param_ventriculomegaly_1_2y;
    }
    //-- Calculate GMFCS score
    var gmf2y_1 = Math.exp(
      param_intercept_2y +
      (param_complete_gest_age_weeks_2y * gest_val) +
      (param_fenton_birth_weight_z_score_2y * fbwz_score_val) +
      (param_fenton_day_14_weight_z_score_2y * fd14wz_score_val) +
      (param_fenton_discharge_weight_z_score_2y * fdwz_score_val) +
      param_wmd_selected +
      param_ventricg_selected
    );
    var gmf2y_2 = gmf2y_1 * 100;
    //var gmf_score_2y = gmf1.toFixed(1) + "% " + final_outcome['green'];
    var gmf_score_2y = gmf2y_2.toFixed(1) + "% ";

    //get risk outcome
    var final_outcome_2y = '';
    if ( gmf2y_1 < final_outcome_limits_2y[0]) {
      final_outcome_2y = 'green';
    } else if ( gmf2y_1 >= final_outcome_limits_2y[0] && gmf2y_1 < final_outcome_limits_2y[1] ) {
      final_outcome_2y = 'yellow';
    } else {
      final_outcome_2y = 'red';
    }

    //10-Years calculations
    //-- Select WMD params
    if (wmd_val === 0) {
      param_wmd_selected = param_white_matter_damage_0_10y;
    } else if (wmd_val === 1) {
      param_wmd_selected = param_white_matter_damage_1_10y;
    }
    //-- Select Ventriculomegaly param
    if (ventricg_val === 0) {
      param_ventricg_selected = param_ventriculomegaly_0_10y;
    } else if (ventricg_val === 1) {
      param_ventricg_selected = param_ventriculomegaly_1_10y;
    }
    var gmf10y_1 = Math.exp(
      param_intercept_10y +
      (param_complete_gest_age_weeks_10y * gest_val) +
      (param_fenton_birth_weight_z_score_10y * fbwz_score_val) +
      (param_fenton_day_14_weight_z_score_10y * fd14wz_score_val) +
      (param_fenton_discharge_weight_z_score_10y * fdwz_score_val) +
      param_wmd_selected +
      param_ventricg_selected
    );
    var gmf10y_2 = gmf10y_1 * 100;
    //var gmf_score_10y = gmf2.toFixed(1) + "% " + final_outcome['red'];
    var gmf_score_10y = gmf10y_2.toFixed(1) + "% ";

    //get risk outcome
    var final_outcome_10y = '';
    if ( gmf10y_1 < final_outcome_limits_10y[0]) {
      final_outcome_10y = 'green';
    } else if ( gmf10y_1 >= final_outcome_limits_10y[0] && gmf10y_1 < final_outcome_limits_10y[1] ) {
      final_outcome_10y = 'yellow';
    } else {
      final_outcome_10y = 'red';
    }

    var gmf_score = {
      '2y'  : gmf_score_2y,
      'outcome_2y': final_outcome_2y,
      '10y' : gmf_score_10y,
      'outcome_10y': final_outcome_10y,
    };
    //} [ {key:'2y', val:gmf_score_2y}, {key:'10y', val:gmf_score_10y}]
    return(gmf_score);
  }

  render() {
    return (
      <div>
       <h2> Risk of Motor Impairment in Extremely Low Gestational Age Newborns </h2>
       <p>
         This tool provides an estimated likelihood of motor impairment
         (defined as a Gross Motor Function Classification Scale level of 2
         or higher)at age 2 and age 10. This is based on known risk factors for
        motor impairment identified in the ELGAN cohort of infants born between
        2002 and 2004 (see publication for more details (
        <a href='http://www.example.com' target='_blank'  rel="noopener noreferrer">
        link here</a> )
      </p>
        <fieldset>
         <legend> Enter your data.</legend>
          <CPalsyInput
            c_age={this.state.c_age}
            gest={this.state.gest}
            fbwz_score={this.state.fbwz_score}
            fd14wz_score={this.state.fd14wz_score}
            fdwz_score={this.state.fdwz_score}
            cpalsy_risk_2y={this.state.cpalsy_risk_2y}
            cpalsy_outcome_2y={this.state.cpalsy_outcome_2y}
            cpalsy_risk_10y={this.state.cpalsy_risk_10y}
            cpalsy_outcome_10y={this.state.cpalsy_outcome_10y}
            handleInputChange={this.handleInputChange}
            handleSubmit={this.handleSubmit}
          />
        </fieldset>
      </div>
    )
  }
}

export default CPalsyCompute;
